<template>
    <ion-page>
        <ion-content :fullscreen="true" class="ion-no-padding">
            <ion-item lines="full" mode="md">
                <ion-label position="stacked">{{ language.username }}</ion-label>
                <ion-input autocomplete="off" :placeholder="language.username" v-model="user.username" />
            </ion-item>
            <ion-item lines="full" mode="md">
                <ion-label position="stacked">{{ language.email }}</ion-label>
                <ion-input autocomplete="off" :placeholder="language.email" v-model="user.email" />
            </ion-item>
            <ion-item lines="full" mode="md">
                <button type="button" class="custom-button button-outline bottom-margin" @click="changePassword">{{ language.changePassword }}</button>
            </ion-item>
            <ion-item lines="full" mode="md" class="ion-item-disabled">
                <ion-label position="stacked">{{ language.userRole }}</ion-label>
                <ion-input autocomplete="off" placeholder="" v-model="user.role.name" />
            </ion-item>
            <ion-item lines="full" mode="md" v-if="user.partner.name" class="ion-item-disabled">
                <ion-label position="stacked">{{ language.partner }}</ion-label>
                <ion-input autocomplete="off" placeholder="" v-model="user.partner.name" />
            </ion-item>
            <div v-if="user.appInstances.length > 0">
                <div class="form-section-header">
                    <p>{{ language.appInstances }}</p>
                </div>
                <div>
                    <ion-radio-group @ionChange="changePreference" :value="user.appInstancePreference">
                        <ion-item lines="full" v-for="appInstance of user.appInstances" :key="appInstance.id" mode="md" >
                            <ion-label color="primary">{{ appInstance.name }}<span v-if="user.appInstancePreference === appInstance.id" class="preference-label"> - {{ language.preference }}</span></ion-label>
                            <ion-radio
                                slot="start"
                                :value="appInstance.id"
                                mode="md"
                                color="primary"></ion-radio>
                        </ion-item>
                    </ion-radio-group>
                </div>
            </div>
        </ion-content>
        <FixedFooter :buttons="footerButtons"/>
    </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";
import {IonContent, IonInput, IonItem, IonLabel, IonPage, IonRadio, IonRadioGroup} from "@ionic/vue";
import {computed, ref} from 'vue';
import router from "@/router";
import FixedFooter from "@/components/FixedFooter.vue";
import {presentNativeAlert, presentValidationAlert} from "@/services/Notify";
import {execute} from "@/mixins/LoadingMixin";
import {saveUser} from "@/services/UserService";
import {AppInstance} from "@/models/AppInstanceModel";
import {getCompleteAppInstances} from "@/services/AppInstanceService";
import {useStore} from "vuex";
import {isValidEmail} from "@/utils/EmailUtil";

export default defineComponent({
    name: "MyAccount",
    components: {
        IonPage,
        IonContent,
        IonItem,
        IonLabel,
        IonInput,
        IonRadio,
        IonRadioGroup,
        FixedFooter
    },
    async ionViewWillEnter() {
        await this.store.dispatch('app/setPageTitle', this.language.menu.myAccount);
        await execute( async () => {
            this.appInstances = await getCompleteAppInstances();
        }, this.language.errors.gettingAppInstances);
        this.user = this.store.getters['auth/loggedInUser'];
        if (this.user.role.name === 'Super Admin') {
            this.user.appInstances = this.appInstances;
        } else if (this.user.role.name === 'Partner Admin') {
            this.user.appInstances = this.appInstances.filter((instance: AppInstance) => instance.partnerId === this.user.partner.id);
        }
    },
    setup() {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const user = ref(store.getters['auth/loggedInUser']);
        const appInstances = ref([] as AppInstance[]);

        const changePassword = () => {
            router.push({ name: 'changeCurrentPassword'});
        }

        const changePreference = (e: any) => {
            user.value.appInstancePreference = e.target.value;
        }

        const updateMyAccount = async () => {
            if (user.value.username.trim() === '') {
                presentValidationAlert(language.value.validation.enterUsername);
            } else if (user.value.username.trim().length < 6) {
                presentValidationAlert(language.value.validation.usernameAtLeast6);
            } else if (user.value.email.trim() === '') {
                presentValidationAlert(language.value.validation.enterEmail);
            } else if (!isValidEmail(user.value.email.trim())) {
                presentValidationAlert(language.value.validation.emailFormat);
            } else {
                await execute(async () => {
                    const data = await saveUser(user.value);
                    if (data) {
                        await store.dispatch('auth/logout');
                        await presentNativeAlert({
                            cssClass: 'custom-alert',
                            header: '',
                            message: language.value.okToLogIn,
                            buttons: [{
                                text: language.value.ok,
                                handler: () => {
                                    router.push({name: 'login'});
                                },
                            }]
                        });
                    }
                }, language.value.errors.updatingAccount);
            }
        }
        
        return {
            store,
            language,
            user,
            changePassword,
            changePreference,
            footerButtons: computed(() => {
                return ([{
                    class: 'custom-button button-clear',
                    icon: false,
                    iconPos: '',
                    handler: () => router.back(),
                    text: language.value.cancel
                },
                {
                    class: 'custom-button button-primary',
                    icon: false,
                    iconPos: '',
                    handler: () => updateMyAccount(),
                    text: language.value.save
                }]);
            }),
            appInstances
        }
    }
});
</script>

<style scoped>
.bottom-margin {
    margin-bottom: 20px;
}

.preference-label {
    color: var(--color-primary-light);
}
</style>